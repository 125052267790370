import React, {useEffect, useState} from 'react';
import parse from 'html-react-parser';
import shortid from  "shortid";

const ProcessSection2 = (props) => {
    const [isActive, setActive] = useState(false);
    const processHandle = () => {
        setActive(!isActive);
    }
    useEffect(() => {
        const body = document.querySelector("body");
        body.style.overflow = "unset";
        const pageWrapper = document.querySelector("#gatsby-focus-wrapper");
        pageWrapper.style.overflow = "unset";

        let mainLinks = document.querySelectorAll(".processes-nav .nav-item a");

        window.addEventListener("scroll", event => {
            let fromTop = window.scrollY;
            mainLinks.forEach(link => {
                let section = document.querySelector(link.hash);
                if(section.offsetTop <= fromTop && section.offsetTop + section.offsetHeight > fromTop){
                    link.classList.add("active");
                }else{
                    link.classList.remove("active")
                }
            })
        })
    },[])
    
    return (
        <section className="section-2">
            <div className="container">
                <div className="process-block">
                    <div className="row">
                        <div className="col-lg-5 mobile-sticky">
                            <div className={`process-indicators ${isActive ? "close" : "open"}`} onClick={processHandle}>
                                <div className="process-nav-header">
                                    <p className="para-26">Design Process</p>
                                    <div className={`arrow ${isActive ? "up" : "down"}`}></div>
                                </div>
                                <ul className="processes-nav">
                                    {props.sidebarlist?.map((object, i) => {
                                        return(
                                            <li className="nav-item" key={shortid.generate()} ><a href={"#"+object.id} className={"secondary-link " +object.extraClasses}>{parse(object.processName)}</a></li>
                                        )
                                    })}
                                </ul>
                            </div>  
                        </div>
                        <div className="col-lg-1"></div>
                        <div className="col-lg-18">
                            <div className="process-content">
                                {props.contentlist?.map((object, i) => {
                                    return(
                                        <div className="content-block" key={shortid.generate()} id={object.id}>
                                            <h2 className="h2">{parse(object.contentTitle)}</h2>
                                            <p>{parse(object.contentDescription)}</p>
                                            {object.contentImageList?.map((img)=> {
                                                return(
                                                    <div className="image-block">
                                                        <img
                                                            src={img.image1x?.sourceUrl}
                                                            srcSet={img.image2x?.sourceUrl + " 2x, " + img.image1x?.sourceUrl + " 1x"}
                                                            width={img.image1x?.width}
                                                            alt={img.image1x?.altText}
                                                        />
                                                    </div>
                                                )
                                            })}
                                            {object.contentTitleAndDescriptionList?.map((ob) => {
                                                return(
                                                    <div className="title-and-description-list">
                                                        <p className="extra-large">{parse(ob.title)}</p>
                                                        <p>{parse(ob.description)}</p>
                                                        <div className="image-block">
                                                            <img
                                                                src={ob.image1x?.sourceUrl}
                                                                srcSet={ob.image2x?.sourceUrl + " 2x, " + ob.image1x?.sourceUrl + " 1x"}
                                                                width={ob.image1x?.width}
                                                                alt={ob.image1x?.altText}
                                                                style={{width: "auto"}}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProcessSection2